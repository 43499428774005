import React, { useState ,Suspense, ReactNode } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { PageTitles } from "../utils/enums";

const HomeRes = React.lazy(()=>import('../components/Router2/Home/Home'))
const About = React.lazy(()=>import('../components/Router2/About/About'))
const Gallery = React.lazy(()=>import('../components/Router2/Gallery/Gallery'))
const Location = React.lazy(()=>import('../components/Location/Location'))
const PageComponent = React.lazy(()=>import('../components/Router2/PageComponent/PageComponent'))
const Meta = React.lazy(()=> import ('../components/Router2/Common/Meta'))

const NotFound404:React.FC = () => <h1>404 Page Not Found</h1>
const Loading:React.FC = () => <h1>Loading...</h1>

const AppRouter:React.FC = () => {

    const [displayReserve, setDisplayReserve] = useState(false);
    const homeImage = require('../assets/Gallery/gallery_009.webp');
    const aboutImage = require('../assets/Store/store_036.webp');
    const galleryImage = require('../assets/Store/003.webp');

    return (
        
        <Router>
            <Switch>
                <>
                    <Route exact path="/">
                        <Redirect to={`/jp/home`} />
                    </Route>
                    <Route exact path='/:lang/home' render={routerProps => (
                        <Suspense fallback={Loading as unknown as ReactNode}>
                            <Meta
                                title="ヒカリの ホームページ"
                                content=" ヒカリの。は目黒区自由が丘にある極上の癒しを提供する、ヘッドスパ＆アロマリラクゼーションサロン。極上の癒しを提供する為だけに作られたサロンは、1名様限定の貸切サロンです。他のお客様を気にすることなく、プライベートな時間をお楽しみ頂けます。"
                                />
                            <PageComponent 
                                usesLogo={true} 
                                image={homeImage}
                                home={'home'}
                                text={PageTitles.home}
                                >
                                    <HomeRes cb={setDisplayReserve}/>
                                
                            </PageComponent>
                        </Suspense>
                        

                    )}/>
                    <Route path='/:lang/about' render={routerProps => (
                        <Suspense fallback={Loading as unknown as ReactNode}>
                        <Meta
                            title="Aboutヒカリの"
                            content="ヒカリの。は静かな場所にある小さなサロンです。
                            スパニストが一人一人のお客様に合わせて、
                            全てをオーダメイドで施術させていただきます。
                            まず初めにご来店いただいた時は少し長めに
                            カウンセリング時間をいただき、しっかりとお悩みを伺います。"
                            />
                        <PageComponent 
                            usesLogo={false}
                            image={aboutImage}
                            text={PageTitles.about}
                            >
                            <About/>

                        </PageComponent>
                        </Suspense>
                    )}/>
                    <Route path='/:lang/gallery' render={routerProps => (
                        <Suspense fallback={Loading as unknown as ReactNode}>
                            <Meta
                                title="Gallery　ヒカリの"
                                />
                            <PageComponent 
                                usesLogo={false}
                                image={galleryImage}
                                text={PageTitles.gallery}
                                >
                                <Gallery/>

                            </PageComponent>                         
                        </Suspense>
                    )}/>
                    <Route exact path='/:lang/access' render={routerProps => (
                        <Suspense fallback={Loading as unknown as ReactNode}>
                            <Meta
                                title="アクセス | Access"
                                content="ヒカリのの店の所在地"
                                />
                            <PageComponent 
                                usesLogo={false}
                                image={homeImage}
                                text={PageTitles.access}
                                >
                                <Location/>

                            </PageComponent>
                        </Suspense>
                    )}/>
                </>
                <Route component={NotFound404}/> 
            </Switch>       
        </Router>
)}




export default AppRouter;

