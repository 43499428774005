import React, { useState, useEffect } from 'react';
import './App.css';
import AppRouter from './routers/AppRouter';

import useWindowDimensions from './hooks/useWindowDimensions';


export interface Store {
  selectLang?:{
    get:string,
    set: (string:string) => void,
  },
  slideout?:{
    get:boolean,
    set:(option:boolean) => void,
  },
  slideoutDisp?:{
    get:boolean,
    set:(option:boolean) => void,
  },
  menuOption?:{
    get:string,
    set:(option:string) => void,
  },
  loading?:{
    get:boolean,
    set:(option:boolean) => void,
  },
  mailSuccess?:{
    get:boolean,
    set:(option:boolean) => void,
  },
  utils?:{
    menuOptions?:string[],
    dimensions?:{
      height?:number,
      width?:number
    }
  }
}

export const AppContext = React.createContext({});

const App = () => {
  const [ lang, setLang ] = useState<string>('jp');
  const [ slideout,setSlideout ] = useState<boolean>(false);
  const [ slideoutDisplay,setSlideoutDisplay ] = useState<boolean>(false);
  const [ optionSelected, setOptionSelected ] = useState<string>('');
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ mailSuccess, setMailSuccess ] = useState<boolean>(false);
  const { height, width } = useWindowDimensions();

  const store:Store = {
    selectLang:{get:lang,set:setLang},
    slideout:{get:slideout, set:setSlideout},
    slideoutDisp:{get:slideoutDisplay, set:setSlideoutDisplay},
    menuOption:{get:optionSelected,set:setOptionSelected},
    loading:{get:loading,set:setLoading},
    mailSuccess:{get:mailSuccess,set:setMailSuccess},
    utils:{
      menuOptions:[ 'Home','About','Gallery', 'Menu', 'Access', 'Reserve','Shop' ],
      dimensions:{
        height:height,
        width:width
      },
    }
  }

  useEffect(()=>{
    console.log(process.env.REACT_APP_ENV);
  },[])

  return (
    <AppContext.Provider value={store} >
        <div className="App">
          <AppRouter />
        </div>
    </AppContext.Provider>
    
  );
}

export default App;
